<template>
  <div class="inputBox">
    <h1 class="welcome">欢迎使用</h1>
    <el-input
      class="account"
      v-model="mobile"
      placeholder="请输入手机号"
      maxlength="11"
    ></el-input>
    <el-input
      v-model="code"
      type="text"
      placeholder="请输入验证码"
      maxlength="6"
    >
      <Svcode slot="suffix" />
    </el-input>
    <el-button class="loginBtn" @click="loginBtnClick">登录</el-button>
    <div class="psdLogin">
      <router-link to="password" replace>密码登录</router-link>
    </div>
  </div>
</template>

<style lang="less" scoped>
.psdLogin {
  margin-top: 22px;
  font-size: 12px;
  text-align: center;
  a {
    color: #f8b849;
  }
}
</style>

<script>
import Svcode from "../../components/Svcode";
export default {
  data() {
    return {
      mobile: "",
      code: ""
    };
  },
  methods: {
    loginBtnClick() {
      // this.$router.replace({ name: "Index" });
      this.$common.postInterface(
        "/api/v1.login/login_sms",
        {
          mobile: this.mobile,
          code: this.code
        },
        this,
        res => {
          if (res.status === "200" && res.data.success) {
            console.log(res);
            this.$store.commit("setPersonal", res.data.user_info);
            sessionStorage.setItem("id", res.data.user_info.id);
            sessionStorage.setItem("role_id", res.data.user_info.role_id);
            sessionStorage.setItem("token", res.data.token);
            this.$router.replace({ name: "Index" });
          } else {
          setmas(res.msg,"error",this)

            // this.$message.error(res.msg);
          }
        }
      );
    }
  },
  components: {
    Svcode
  }
};
// 提醒弹出框
function setmas(msg,type,vm){
       vm.$message({
                  message:msg,
                  type:type,
                  customClass:"popup"
                  
    });
}
</script>
<style>
.popup {
  width: 400px;
  height: 70px;
  font-size: 26px;
  font-weight: 700;

  position: absolute !important;
  top: 45% !important;
  margin-top: -35px !important;
}
</style>